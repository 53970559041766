import React, { useEffect } from "react";
import Head from "next/head";
import createProductTilesMap from "@serenaandlily/utils/productsPreprocessing/createProductTilesMap";
import { createProductsMap, injectImageData } from "@utils";
import GenericPageWrapper from "../wrappers/GenericPageWrapper";
import useUser from "@lib/useUser";
import Metadata from "@components/Metadata";
import { fetchGlobalContent, getContentItems } from "@utils";
import injectUgcImages from "@utils/injectUgcImages";
import buildAmpClient from "@lib/buildAmpClient";
import { useDiscountContext } from "@serenaandlily/contexts/ProductDiscountContext";
import Content from "@components/Content";
import useCheckImageAlt from "@serenaandlily/hooks/useCheckImageAlt";

export default function Home({
  globalContent,
  content,
  discountMessageSettings
}) {
  const { metadata = {}, body } = content || {};
  const { emptyBagMessage, siteGlobals } = globalContent || {};
  const { setDiscountSettings } = useDiscountContext();

  useEffect(() => {
    const setCookieForNewUser = async () => {
      const handleCookies = (await import("@serenaandlily/utils/handleCookies"))
        .default;
      handleCookies("newUser").updateCookie("false");
    };

    setCookieForNewUser();
  }, []);

  useEffect(() => {
    if (discountMessageSettings?.dictionary) {
      setDiscountSettings(discountMessageSettings.dictionary);
    }
  }, [discountMessageSettings]);

  useCheckImageAlt(siteGlobals?.defaultAltTexts);

  return (
    <React.Fragment>
      <Metadata {...metadata} />
      <Head>
        <link rel="canonical" href="https://www.serenaandlily.com" />
      </Head>
      <GenericPageWrapper
        {...globalContent}
        useUser={useUser}
        emptyBagMessage={emptyBagMessage}
      >
        {body &&
          body.map((bodyContent, idx) => {
            const key = `homepage-body-content-${idx}`;
            return (
              <Content key={key} contentItemIndex={idx} {...bodyContent} />
            );
          })}
      </GenericPageWrapper>
    </React.Fragment>
  );
}

export async function getStaticProps(context) {
  const amplienceClient = await buildAmpClient(context);
  const constructorId = process.env.CONSTRUCTOR_BEACON_ID || "";
  const res = await amplienceClient.getContentItemByKey("homepage");

  const content = await res.toJSON();

  const gqlProducts = await createProductsMap(content?.body);
  const initialProductTiles = await createProductTilesMap(gqlProducts);
  await injectImageData(content);
  await injectUgcImages(content);

  let discountMessageSettings = {},
    dsoShoppingBagPayAndCarryOptionsHeadline = {};

  try {
    [discountMessageSettings, dsoShoppingBagPayAndCarryOptionsHeadline] =
      await getContentItems(amplienceClient, [
        { key: "product-discount-settings" },
        { key: "dso-shoppingbag-payandcarry-options-headline" }
      ]);
  } catch (e) {
    //eslint-disable-next-line no-console
    console.log(e);
  }

  const globalContent = await fetchGlobalContent(amplienceClient);
  if (globalContent.footer) {
    content.body.push(globalContent.footer);
    delete globalContent.footer;
  }

  return {
    props: {
      content,
      initialProductTiles,
      discountMessageSettings,
      constructorId,
      dsoShoppingBagPayAndCarryOptionsHeadline,
      globalContent: globalContent
    },

    revalidate: 10
  };
}
