import { useEffect } from "react";

const DEFAULT_TEXT = "Serena & Lily Design-driven luxury furniture, bedding, home accessories & interior design"

const useCheckImageAlt = (text = DEFAULT_TEXT) => {
  useEffect(() => {
    const setAltText = () => {
      const images = document.querySelectorAll("img");
      const imagesWithoutAlt = Array.from(images).filter((img) => !img.alt);
      imagesWithoutAlt.forEach((img) => {
        img.alt = text;
      });
    };

    setAltText();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          setAltText();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => {
      observer.disconnect();
    };
  }, [text]);
};

export default useCheckImageAlt;
